export enum PaymentMethod {
  applepay = 'applepay',
  bancontact = 'bancontact',
  banktransfer = 'banktransfer',
  belfius = 'belfius',
  creditcard = 'creditcard',
  directdebit = 'directdebit',
  eps = 'eps',
  giftcard = 'giftcard',
  giropay = 'giropay',
  ideal = 'ideal',
  kbc = 'kbc',
  klarnapaylater = 'klarnapaylater',
  klarnasliceit = 'klarnasliceit',
  paypal = 'paypal',
  paysafecard = 'paysafecard',
  przelewy24 = 'przelewy24',
}

export enum PaymentStatus {
  open = 'open',
  canceled = 'canceled',
  pending = 'pending',
  authorized = 'authorized',
  expired = 'expired',
  failed = 'failed',
  paid = 'paid',
}
