/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

 module.exports = {
  logo: 'bluerock.svg',
  logoalt: 'bluerock-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_bluerock.scss',
  name: 'BlueRock',
  clientName: 'bluerock',
  phone: '+31 0 31 87 96 060',
  email: 'info@bluerocktms.com',
  website: 'https://bluerocktms.com',
  glossary: true,
  // langFileName: 'bluerock.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#006a77',
      color2: '#fd65a7',
      color3: '#00001e',
      color4: '#307ec1',
    },
  },
  language: 'en',
  showAnimations: true,
  showAssetDescription: true,
};
